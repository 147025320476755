import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useDioneExecutorContract } from 'hooks/useContract'
import { useToast } from '@dione/uikit'
import { convertContractToInterface } from 'hooks/contracts/utils'
import { useContractReads, useContractRead } from 'wagmi'
import { useMemo } from 'react'
import { getDioneGovernanceAddress } from 'utils/addressHelpers'
import { isAddress } from 'utils'
import { zeroAddress } from 'viem'

const settingsFuncNames = [
  'PROPOSITION_THRESHOLD',
  'VOTING_DURATION',
  'VOTE_DIFFERENTIAL',
  'MINIMUM_QUORUM',
  'GRACE_PERIOD',
  'MINIMUM_DELAY',
  'MAXIMUM_DELAY',
  'getDelay',
]

export function useExecutorSettings(address?: string): [boolean, boolean, any] {
  const executorContract = useDioneExecutorContract(isAddress(address) ? address : zeroAddress)
  const { toastError } = useToast()
  const executorInterface = convertContractToInterface(executorContract)

  // @ts-ignore
  const { data, isLoading, isError, status } = useContractReads({
    // @ts-ignore
    contracts: settingsFuncNames.map((fn) => ({
      ...executorInterface,
      functionName: fn,
    })),
    suspense: !address,
    allowFailure: true,
    onError: (error) => {
      toastError("Couldn't fetch the executor settings", `Reason: ${error.message}`)
    },
  })

  return useMemo(() => {
    if (status === 'loading') return [true, false, Object.fromEntries(settingsFuncNames.map((fn) => [fn, 0n]))]
    if (status === 'error') return [false, true, Object.fromEntries(settingsFuncNames.map((fn) => [fn, 0n]))]
    return [
      isLoading,
      isError,
      Object.fromEntries(settingsFuncNames.map((fn, idx) => [fn, BigInt(`${data?.[idx]?.result || 0}`)])),
    ]
  }, [data, isLoading, isError, status])
}

export function useValidateCreatorOfProposal(address?: string): [boolean, boolean, boolean] {
  const { account, chainId } = useActiveWeb3React()
  const executorContract = useDioneExecutorContract(isAddress(address) ? address : zeroAddress)
  const { toastError } = useToast()
  const executorInterface = convertContractToInterface(executorContract)

  // @ts-ignore
  const { data, isLoading, isError, status } = useContractRead({
    ...executorInterface,
    // @ts-ignore
    functionName: 'validateCreatorOfProposal',
    // @ts-ignore
    args: [getDioneGovernanceAddress(chainId), account],
    // @ts-ignore
    allowFailure: true,
    // @ts-ignore
    onError: (error) => {
      toastError("Couldn't validate the proposal creator power", `Reason: ${error.message}`)
    },
  })

  return useMemo(() => {
    if (status === 'loading') return [true, false, false]
    if (status === 'error' || !(account && address)) return [false, true, false]
    return [isLoading, isError, Boolean(data)]
  }, [data, isLoading, isError, status, account, address])
}

export function usePropositionRequirements(address?: string): [boolean, boolean, bigint] {
  const { chainId } = useActiveWeb3React()
  const executorContract = useDioneExecutorContract(isAddress(address) ? address : zeroAddress)
  const { toastError } = useToast()
  const executorInterface = convertContractToInterface(executorContract)

  // @ts-ignore
  const { data, isLoading, isError, status } = useContractRead({
    ...executorInterface,
    functionName: 'getMinimumPropositionPowerNeeded',
    args: [getDioneGovernanceAddress(chainId)],
    // @ts-ignore
    allowFailure: true,
    // @ts-ignore
    onError: (error) => {
      if (address) toastError("Couldn't get the proposal creator power", `Reason: ${error.message}`)
    },
  })

  return useMemo(() => {
    if (status === 'loading') return [true, false, 0n]
    if (status === 'error') return [false, true, 0n]
    return [isLoading, isError, BigInt(`${data || 0}`)]
  }, [data, isLoading, isError, status])
}

export function useIsProposalPassed(address?: string, proposalId?: number): [boolean, boolean, boolean] {
  const executorContract = useDioneExecutorContract(isAddress(address) ? address : zeroAddress)
  const { toastError } = useToast()
  const { chainId } = useActiveWeb3React()
  const executorInterface = convertContractToInterface(executorContract)

  // @ts-ignore
  const { data, isLoading, isError, status } = useContractRead({
    ...executorInterface,
    functionName: 'isProposalPassed',
    args: [getDioneGovernanceAddress(chainId), proposalId],
    // @ts-ignore
    allowFailure: true,
    // @ts-ignore
    enabled: proposalId > -1 && address,
  })

  return useMemo(() => {
    if (status === 'loading') return [true, false, false]
    if (status === 'error') return [false, true, false]
    return [isLoading, isError, Boolean(data)]
  }, [data, isLoading, isError, status])
}

export function useIsProposalOverGracePeriod(address?: string, proposalId?: number): [boolean, boolean, boolean] {
  const executorContract = useDioneExecutorContract(isAddress(address) ? address : zeroAddress)
  const { toastError } = useToast()
  const { chainId } = useActiveWeb3React()
  const executorInterface = convertContractToInterface(executorContract)

  // @ts-ignore
  const { data, isLoading, isError, status } = useContractRead({
    ...executorInterface,
    functionName: 'isProposalOverGracePeriod',
    args: [getDioneGovernanceAddress(chainId), proposalId],
    // @ts-ignore
    allowFailure: true,
    // @ts-ignore
    onError: (error) => {
      toastError("Couldn't fetch proposal status", `Reason: ${error.message}`)
    },
    // @ts-ignore
    enabled: proposalId > -1 && address,
  })

  return useMemo(() => {
    if (status === 'loading') return [true, false, false]
    if (status === 'error') return [false, true, false]
    return [isLoading, isError, Boolean(data)]
  }, [data, isLoading, isError, status])
}
