import { useDioneGovernanceContract } from 'hooks/useContract'
import { convertContractToInterface } from 'hooks/contracts/utils'
import { useContractWrite, useWaitForTransaction } from 'wagmi'
import { useMemo } from 'react'
import { useToast } from '@dione/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

export function useCreateProposal(withWaiter = false, callback = undefined) {
  const governanceContract = useDioneGovernanceContract()
  const { chainId } = useActiveWeb3React()
  const { toastSuccess } = useToast()
  const governanceInterface = convertContractToInterface(governanceContract)

  // @ts-ignore
  const { data, isLoading, write } = useContractWrite({
    ...governanceInterface,
    // @ts-ignore
    functionName: 'create',
    // @ts-ignore
    chainId,
    onSuccess: (d) => {
      if (callback) callback(d)

      toastSuccess('Proposal created!')
    },
  })

  const waiterHash = useMemo(() => {
    if (!data || !data?.hash || !withWaiter) return undefined

    return `${data?.hash}`
  }, [withWaiter, data])

  // @ts-ignore
  const { isLoading: waiterLoading } = useWaitForTransaction({
    // @ts-ignore
    hash: waiterHash,
    enabled: !!waiterHash,
  })

  return useMemo(() => {
    if (!write) return [true, undefined]

    // noinspection TypeScriptValidateTypes
    return [
      isLoading || waiterLoading,
      (args, overrideParams) =>
        // @ts-ignore
        write({
          args,
          ...overrideParams,
        }),
    ]
  }, [isLoading, waiterLoading, write])
}

export function useCancelProposal(withWaiter = false, callback = undefined) {
  const governanceContract = useDioneGovernanceContract()
  const { toastSuccess } = useToast()
  const governanceInterface = convertContractToInterface(governanceContract)

  // @ts-ignore
  const { data, isLoading, write } = useContractWrite({
    ...governanceInterface,
    // @ts-ignore
    functionName: 'cancel',
    onSuccess: (d) => {
      if (callback) callback(d)

      toastSuccess('Proposal canceled!')
    },
  })

  const waiterHash = useMemo(() => {
    if (!data || !data?.hash || !withWaiter) return undefined

    return `${data?.hash}`
  }, [withWaiter, data])

  // @ts-ignore
  const { isLoading: waiterLoading } = useWaitForTransaction({
    // @ts-ignore
    hash: waiterHash,
    enabled: !!waiterHash,
  })

  return useMemo(() => {
    if (!write) return [true, undefined]

    // noinspection TypeScriptValidateTypes
    return [
      isLoading || waiterLoading,
      (args, overrideParams) =>
        // @ts-ignore
        write({
          args,
          ...overrideParams,
        }),
    ]
  }, [isLoading, waiterLoading, write])
}

export function useQueueProposal(withWaiter = false, callback = undefined) {
  const governanceContract = useDioneGovernanceContract()
  const { toastSuccess } = useToast()
  const governanceInterface = convertContractToInterface(governanceContract)

  // @ts-ignore
  const { data, isLoading, write } = useContractWrite({
    ...governanceInterface,
    // @ts-ignore
    functionName: 'queue',
    onSuccess: (d) => {
      if (callback) callback(d)

      toastSuccess('Proposal queued!')
    },
  })

  const waiterHash = useMemo(() => {
    if (!data || !data?.hash || !withWaiter) return undefined

    return `${data?.hash}`
  }, [withWaiter, data])

  // @ts-ignore
  const { isLoading: waiterLoading } = useWaitForTransaction({
    // @ts-ignore
    hash: waiterHash,
    enabled: !!waiterHash,
  })

  return useMemo(() => {
    if (!write) return [true, undefined]

    // noinspection TypeScriptValidateTypes
    return [
      isLoading || waiterLoading,
      (args, overrideParams) =>
        // @ts-ignore
        write({
          args,
          ...overrideParams,
        }),
    ]
  }, [isLoading, waiterLoading, write])
}

export function useExecuteProposal(withWaiter = false, callback = undefined) {
  const governanceContract = useDioneGovernanceContract()
  const { toastSuccess } = useToast()
  const governanceInterface = convertContractToInterface(governanceContract)

  // @ts-ignore
  const { data, isLoading, write } = useContractWrite({
    ...governanceInterface,
    // @ts-ignore
    functionName: 'execute',
    onSuccess: (d) => {
      if (callback) callback(d)

      toastSuccess('Proposal executed!')
    },
  })

  const waiterHash = useMemo(() => {
    if (!data || !data?.hash || !withWaiter) return undefined

    return `${data?.hash}`
  }, [withWaiter, data])

  // @ts-ignore
  const { isLoading: waiterLoading } = useWaitForTransaction({
    // @ts-ignore
    hash: waiterHash,
    enabled: !!waiterHash,
  })

  return useMemo(() => {
    if (!write) return [true, undefined]

    // noinspection TypeScriptValidateTypes
    return [
      isLoading || waiterLoading,
      (args, overrideParams) =>
        // @ts-ignore
        write({
          args,
          ...overrideParams,
        }),
    ]
  }, [isLoading, waiterLoading, write])
}

export function useSubmitVote(withWaiter = false, callback = undefined) {
  const governanceContract = useDioneGovernanceContract()
  const { toastSuccess } = useToast()
  const governanceInterface = convertContractToInterface(governanceContract)

  // @ts-ignore
  const { data, isLoading, write } = useContractWrite({
    ...governanceInterface,
    // @ts-ignore
    functionName: 'submitVote',
    onSuccess: (d) => {
      if (callback) callback(d)

      toastSuccess('Your vote submitted!')
    },
  })

  const waiterHash = useMemo(() => {
    if (!data || !data?.hash || !withWaiter) return undefined

    return `${data?.hash}`
  }, [withWaiter, data])

  // @ts-ignore
  const { isLoading: waiterLoading } = useWaitForTransaction({
    // @ts-ignore
    hash: waiterHash,
    enabled: !!waiterHash,
  })

  return useMemo(() => {
    if (!write) return [true, undefined]

    // noinspection TypeScriptValidateTypes
    return [
      isLoading || waiterLoading,
      (args, overrideParams) =>
        // @ts-ignore
        write({
          args,
          ...overrideParams,
        }),
    ]
  }, [isLoading, waiterLoading, write])
}
