import { useDioneGovernanceContract } from 'hooks/useContract'
import { useToast } from '@dione/uikit'
import { convertContractToInterface } from 'hooks/contracts/utils'
import { useContractRead } from 'wagmi'
import { useMemo } from 'react'
import { zeroAddress } from 'viem'
import { isAddress } from 'utils'
import { CallState, useSingleCallResult, useSingleContractMultipleData } from 'state/multicall/hooks'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

export function useIsValidExecutor(address?: string): [boolean, boolean, boolean] {
  const governanceContract = useDioneGovernanceContract()
  const { toastError } = useToast()
  const governanceInterface = convertContractToInterface(governanceContract)

  // @ts-ignore
  const { data, isLoading, isError, status } = useContractRead({
    ...governanceInterface,
    functionName: 'isExecutorAuthorized',
    args: [isAddress(address) ? address : zeroAddress],
    // @ts-ignore
    allowFailure: true,
    onError: (error) => {
      toastError("Couldn't fetch executor authorization", `Reason: ${error.message}`)
    },
    // @ts-ignore
    enabled: address,
  })

  return useMemo(() => {
    if (status === 'loading') return [true, false, false]
    if (status === 'error') return [false, true, false]
    return [isLoading, isError, Boolean(data)]
  }, [data, isLoading, isError, status])
}

export function useGuardianAddress(): [boolean, boolean, string] {
  const governanceContract = useDioneGovernanceContract()
  const { toastError } = useToast()
  const governanceInterface = convertContractToInterface(governanceContract)

  // @ts-ignore
  const { data, isLoading, isError, status } = useContractRead({
    ...governanceInterface,
    functionName: 'useGuardianAddress',
    // @ts-ignore
    allowFailure: true,
    onError: (error) => {
      toastError("Couldn't fetch guardian address", `Reason: ${error.message}`)
    },
    // @ts-ignore
    staleTime: Infinity,
  })

  return useMemo(() => {
    if (status === 'loading') return [true, false, zeroAddress]
    if (status === 'error') return [false, true, zeroAddress]
    return [isLoading, isError, data as string]
  }, [data, isLoading, isError, status])
}

export function useProposalsCount(): [boolean, boolean, bigint] {
  const governanceContract = useDioneGovernanceContract()

  // @ts-ignore
  const {
    result,
    loading: isLoading,
    error: isError,
  } = useSingleCallResult({
    contract: governanceContract,
    functionName: 'getProposalsCount',
    // @ts-ignore
    args: [],
  })

  return useMemo(() => {
    if (isLoading) return [true, false, 0n]
    if (isError) return [false, true, 0n]
    return [isLoading, isError, BigInt(`${result || 0}`)]
  }, [result, isLoading, isError])
}

export function useProposals(): [boolean, boolean, any, any] {
  // @todo: update all any to the proposal types
  const governanceContract = useDioneGovernanceContract()
  const [countLoading, countError, proposalsCount] = useProposalsCount()

  const fetcherArgs = useMemo(() => {
    if (countLoading || countError || !proposalsCount) return []

    return [...Array(Number(proposalsCount)).keys()].map((k) => [k]).reverse()
  }, [countLoading, countError, proposalsCount])

  // @ts-ignore
  const results = useSingleContractMultipleData({
    contract: governanceContract,
    functionName: 'getProposalById',
    // @ts-ignore
    args: fetcherArgs,
  })

  const stateResults = useSingleContractMultipleData({
    contract: governanceContract,
    functionName: 'getProposalState',
    // @ts-ignore
    args: fetcherArgs,
  })

  return useMemo(() => {
    return [
      results.findIndex((res: CallState) => res.loading) > -1 ||
        stateResults.findIndex((res: CallState) => res.loading) > -1,
      results.findIndex((res: CallState) => !res.valid || res.error) > -1 ||
        stateResults.findIndex((res: CallState) => !res.valid || res.error) > -1,
      results?.map((res: CallState) => {
        if (!res.valid || res.loading || res.error) return {}

        return res.result
      }),
      stateResults?.map((res: CallState) => {
        if (!res.valid || res.loading || res.error) return {}

        return res.result
      }),
    ]
  }, [results, stateResults])
}

export function useProposalVotesCount(proposalId?: number): [boolean, boolean, bigint] {
  const governanceContract = useDioneGovernanceContract()
  const governanceInterface = convertContractToInterface(governanceContract)

  // @ts-ignore
  const { data, isLoading, isError } = useContractRead({
    ...governanceInterface,
    functionName: 'getVotesLength',
    args: [proposalId],
    // @ts-ignore
    allowFailure: true,
  })

  return useMemo(() => {
    if (isLoading) return [true, false, 0n]
    if (isError) return [false, true, 0n]
    return [isLoading, isError, BigInt(`${data || 0}`)]
  }, [data, isLoading, isError])
}

export function useProposalState(proposalId?: number): [boolean, boolean, bigint] {
  const governanceContract = useDioneGovernanceContract()
  const governanceInterface = convertContractToInterface(governanceContract)

  // @ts-ignore
  const { data, isLoading, isError } = useContractRead({
    ...governanceInterface,
    functionName: 'getProposalState',
    args: [proposalId],
    // @ts-ignore
    allowFailure: true,
  })

  return useMemo(() => {
    if (isLoading) return [true, false, 0n]
    if (isError) return [false, true, 0n]
    return [isLoading, isError, BigInt(`${data}` || 0n)]
  }, [data, isLoading, isError])
}

export function useProposal(proposalId?: number): [boolean, boolean, any] {
  const governanceContract = useDioneGovernanceContract()
  const governanceInterface = convertContractToInterface(governanceContract)

  // @ts-ignore
  const { data, isLoading, isError } = useContractRead({
    ...governanceInterface,
    functionName: 'getProposalById',
    args: [proposalId],
    // @ts-ignore
    allowFailure: true,
    // @ts-ignore
    staleTime: Infinity,
  })

  return useMemo(() => {
    if (isLoading) return [true, false, undefined]
    if (isError) return [false, true, undefined]
    return [isLoading, isError, data]
  }, [data, isLoading, isError])
}

export function useProposalVotes(proposalId?: number): [boolean, boolean, any] {
  const governanceContract = useDioneGovernanceContract()
  const [countLoading, countError, votesCount] = useProposalVotesCount(proposalId)

  const fetcherArgs = useMemo(() => {
    if (countLoading || countError || !votesCount || typeof proposalId !== 'number') return []

    return [...Array(Number(votesCount)).keys()].map((k) => [proposalId, k])
  }, [countLoading, countError, votesCount, proposalId])

  // @ts-ignore
  const results = useSingleContractMultipleData({
    contract: governanceContract,
    functionName: 'getVoteAtIndex',
    // @ts-ignore
    args: fetcherArgs,
    options: {
      enabled: proposalId > -1,
    },
  })

  return useMemo(() => {
    return [
      results?.findIndex((res: CallState) => res?.loading) > -1,
      results?.findIndex((res: CallState) => res?.error) > -1,
      results?.map((res: CallState) => {
        if (!res.valid || res.loading || res.error) return {}

        return res.result
      }),
    ]
  }, [results])
}

export function useUserVote(proposalId?: number): [boolean, boolean, any] {
  const governanceContract = useDioneGovernanceContract()
  const { account } = useActiveWeb3React()
  const governanceInterface = convertContractToInterface(governanceContract)

  // @ts-ignore
  const { data, isLoading, isError } = useContractRead({
    ...governanceInterface,
    functionName: 'getVoteOnProposal',
    args: [proposalId, account || zeroAddress],
    // @ts-ignore
    allowFailure: true,
  })

  return useMemo(() => {
    if (!account) return [false, false, undefined]
    if (isLoading) return [true, false, undefined]
    if (isError) return [false, true, undefined]
    return [isLoading, isError, data]
  }, [data, isLoading, isError, account])
}
