import { ChainId } from '@dione/sdk'

export const ADMINS = [
  '0xBac9cAA3BfaEcFA9565E9B0AF8b0FB3394923892', // TEMPORARY TESTNET DEPLOYER ACCOUNT
].map((address) => address.toLowerCase())
export const PROPOSALS_TO_SHOW = 10

export interface GovernanceConfig {
  chainId: ChainId
  votingAssetName: string
  averageNetworkBlockTime: number
  ipfsGateway: string
  fallbackIpfsGateway: string
}

export const governanceConfig: GovernanceConfig = {
  chainId: ChainId.ODYSSEY_TESTNET,
  votingAssetName: 'DIONE',
  averageNetworkBlockTime: 6,
  ipfsGateway: 'https://cloudflare-ipfs.com/ipfs',
  fallbackIpfsGateway: 'https://ipfs.io/ipfs',
}
