import { useMemo } from 'react'
import { useContractReads } from 'wagmi'
import { useGovernanceStrategyContract } from 'hooks/useContract'
import { convertContractToInterface } from 'hooks/contracts/utils'
import { useToast } from '@dione/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { zeroAddress } from 'viem'

export function useGovernanceTotalSupply(): [boolean, boolean, bigint, bigint] {
  const strategyContract = useGovernanceStrategyContract()
  const { toastError } = useToast()

  const strategyInterface = convertContractToInterface(strategyContract)

  // @ts-ignore
  const { data, isLoading, isError, status } = useContractReads({
    contracts: [
      // @ts-ignore
      {
        ...strategyInterface,
        functionName: 'getTotalPropositionSupply',
      },
      // @ts-ignore
      {
        ...strategyInterface,
        functionName: 'getTotalVotingSupply',
      },
    ],
    // @ts-ignore
    allowFailure: true,
    // @ts-ignore
    staleTime: Infinity,
    onError: (error) => {
      toastError("Couldn't fetch the total supply", `Reason: ${error.message}`)
    },
  })

  return useMemo(() => {
    if (status === 'loading') return [true, false, 0n, 0n]
    if (status === 'error') return [false, true, 0n, 0n]
    return [isLoading, isError, BigInt(`${data?.[0]?.result || 0n}`), BigInt(`${data?.[1]?.result || 0n}`)]
  }, [data, isLoading, isError, status])
}

export function useUserGovernancePower(): [boolean, boolean, bigint, bigint] {
  const { account } = useActiveWeb3React()
  const strategyContract = useGovernanceStrategyContract()
  const { toastError } = useToast()

  const strategyInterface = convertContractToInterface(strategyContract)

  // @ts-ignore
  const { data, isLoading, isError, status } = useContractReads({
    contracts: [
      // @ts-ignore
      {
        ...strategyInterface,
        functionName: 'getPropositionPower',
        args: [account || zeroAddress],
      },
      // @ts-ignore
      {
        ...strategyInterface,
        functionName: 'getVotingPower',
        args: [account || zeroAddress],
      },
    ],
    // @ts-ignore
    allowFailure: true,
    // @ts-ignore
    enabled: account,
    onError: (error) => {
      toastError("Couldn't fetch the connect wallet power", `Reason: ${error.message}`)
    },
  })

  return useMemo(() => {
    if (status === 'loading') return [true, false, 0n, 0n]
    if (status === 'error') return [false, true, 0n, 0n]
    return [isLoading, isError, BigInt(`${data?.[0]?.result || 0n}`), BigInt(`${data?.[1]?.result || 0n}`)]
  }, [data, isLoading, isError, status])
}
